/**
 * Page-specific Javascript file.  Should generally be included as a separate asset bundle in your page template.
 * example: {{ assets.js('js/pages/sign-in-or-register') | raw }}
 *
 * This script depends on validation rules specified in pages/partials/page.js.twig.
 *
 * Target page: all pages
 */


function customizePDF() {
    var pdfbuton = [{
        text: 'Custom PDF',
        extend: 'pdfHtml5',
        filename: 'dt_custom_pdf',
        orientation: 'landscape', //portrait
        pageSize: 'A4', //A3 , A5 , A6 , legal , letter
        exportOptions: {
            columns: ':visible',
            search: 'applied',
            order: 'applied'
        },
        customize: function (doc) {
            //Remove the title created by datatTables
            doc.content.splice(0, 1);
            //Create a date string that we use in the footer. Format is dd-mm-yyyy
            var now = new Date();
            var jsDate = now.getDate() + '-' + (now.getMonth() + 1) + '-' + now.getFullYear();
            // Logo converted to base64
            // var logo = getBase64FromImageUrl('https://datatables.net/media/images/logo.png');
            // The above call should work, but not when called from codepen.io
            // So we use a online converter and paste the string in.
            // Done on http://codebeautify.org/image-to-base64-converter
            // It's a LONG string scroll down to see the rest of the code !!!
            var logo = '';

            // A documentation reference can be found at
            // https://github.com/bpampuch/pdfmake#getting-started
            // Set page margins [left,top,right,bottom] or [horizontal,vertical]
            // or one number for equal spread
            // It's important to create enough space at the top for a header !!!
            doc.pageMargins = [20, 60, 20, 30];
            // Set the font size fot the entire document
            doc.defaultStyle.fontSize = 7;
            // Set the fontsize for the table header
            doc.styles.tableHeader.fontSize = 7;
            // Create a header object with 3 columns
            // Left side: Logo
            // Middle: brandname
            // Right side: A document title
            doc['header'] = (function () {
                return {
                    columns: [{
                            image: logo,
                            width: 24
                        },
                        {
                            alignment: 'left',
                            italics: true,
                            text: 'dataTables',
                            fontSize: 18,
                            margin: [10, 0]
                        },
                        {
                            alignment: 'right',
                            fontSize: 14,
                            text: 'Custom PDF export with dataTables'
                        }
                    ],
                    margin: 20
                }
            });
            // Create a footer object with 2 columns
            // Left side: report creation date
            // Right side: current page and total pages
            doc['footer'] = (function (page, pages) {
                return {
                    columns: [{
                            alignment: 'left',
                            text: ['Created on: ', {
                                text: jsDate.toString()
                            }]
                        },
                        {
                            alignment: 'right',
                            text: ['page ', {
                                text: page.toString()
                            }, ' of ', {
                                text: pages.toString()
                            }]
                        }
                    ],
                    margin: 20
                }
            });
            // Change dataTable layout (Table styling)
            // To use predefined layouts uncomment the line below and comment the custom lines below
            // doc.content[0].layout = 'lightHorizontalLines'; // noBorders , headerLineOnly
            var objLayout = {};
            objLayout['hLineWidth'] = function (i) {
                return .5;
            };
            objLayout['vLineWidth'] = function (i) {
                return .5;
            };
            objLayout['hLineColor'] = function (i) {
                return '#aaa';
            };
            objLayout['vLineColor'] = function (i) {
                return '#aaa';
            };
            objLayout['paddingLeft'] = function (i) {
                return 4;
            };
            objLayout['paddingRight'] = function (i) {
                return 4;
            };
            doc.content[0].layout = objLayout;
        }
    }];

}


$(document).ready(function () {
    if (jQuery("#cm_importrecs").length) {
        jQuery("#cm_importrecs").ufForm({
            validator: {},
            showAlertOnSuccess: true,
            msgTarget: $("#cr-crudpage-alerts")
        });
    }
});